import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAPI } from '../../api';
import { useAuth } from '../../auth/AuthProvider';
import PageSection from '../../components/PageSection';
import DataTable from '../../components/DataTable';
import RawJsonDisplay from '../../components/RawJsonDisplay';
import PropEditor from '../../components/PropEditor';
import Avatar from '../../components/Avatar';

const Profile = () => {
	const api = useAPI();
	const auth = useAuth();

	const cookieDeviceID = document.cookie?.split('; ')?.find(row => row.startsWith('udevid='))?.split('=')?.[1];

	const [changePasswordState, setChangePasswordState] = useState({ oldPassword: '', password: '', confirmPassword: '' });
	const [twoFactorData, setTwoFactorData] = useState({ enabled: auth.profile.auth.twoFactor?.enabled, secret: '', recoveryCodes: [], verifyCode: '' });
	const [newAvatar, setNewAvatar] = useState(null);

	const changePassword = () => {
		if (changePasswordState.password !== changePasswordState.confirmPassword)
			return alert('Passwords do not match');

		api.put(`/api/auth/profile/password`, {
			oldPassword: changePasswordState.oldPassword,
			password: changePasswordState.password 
		})
		.then(() => {
			alert('Password changed');
			setChangePasswordState({ oldPassword: '', password: '', confirmPassword: '' });
		});
	}

	const reloadProfile = () => {
		api.get('/api/auth/profile')
		.then(json => {
			auth.update(json);
		});
	};

	const reloadAvatar = async () => {
		await Promise.all([
			fetch(`/api/users/${auth.profile.id}/avatar`, { cache: 'reload' }),
			fetch(`/api/users/${auth.profile.id}/avatar?size=small`, { cache: 'reload' }),
			fetch(`/api/users/${auth.profile.id}/avatar?size=medium`, { cache: 'reload' }),
			fetch(`/api/users/${auth.profile.id}/avatar?size=large`, { cache: 'reload' })
		]);

		document.querySelectorAll('img.avatar').forEach(img => {
			let oldSrc = img.src;
			img.src = '';
			setTimeout(() => img.src = oldSrc, 100);
		});
	};

	useEffect(() => {
		reloadProfile();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PageSection title={`Profile: ${auth.profile.name} (${auth.user})`} level={3} />

			<PageSection title="Avatar" level={3}>
				<div>
					<div style={{ display: 'inline-block', marginRight: '1em', verticalAlign: 'top' }}>
						<p>Large</p>
						<Avatar src={`/api/users/${auth.profile.id}/avatar?size=large`} alt="avatar" size="256px" radius="32px" />
					</div>
					<div style={{ display: 'inline-block', marginRight: '1em', verticalAlign: 'top' }}>
						<p>Medium</p>
						<Avatar src={`/api/users/${auth.profile.id}/avatar?size=medium`} alt="avatar" size="128px" radius="16px" />
					</div>
					<div style={{ display: 'inline-block', marginRight: '1em', verticalAlign: 'top' }}>
						<p>Small</p>
						<Avatar src={`/api/users/${auth.profile.id}/avatar?size=small`} alt="avatar" size="48px" radius="8px" />
					</div>
				</div>

				<p>
					<input type="file" accept="image/*" onChange={e => {
						let file = e.target.files[0];
						setNewAvatar(file);
					}} />

					{
						newAvatar && 
						(
							<button onClick={() => {
								api.upload('/api/auth/profile/avatar', newAvatar)
								.then(() => {
									reloadAvatar();
									setNewAvatar(null);
								});
							}}>Upload Avatar</button>
						)
					}
				</p>

				<p>
					<button onClick={() => {
						//api.post('/api/auth/profile/avatar/reset')
						if (window.confirm('Are you sure you want to reset your avatar?')) {
							api.post(`/api/auth/profile/avatar/reset`)
							.then(() => {
								reloadAvatar();
								reloadProfile();
							});
						}
					}}>Reset Avatar</button>
				</p>
			</PageSection>

			<PageSection title={auth?.profile?.auth?.strategies?.includes('local') ? 'Change Password' : 'Create Password'} level={3}>
				<PropEditor
					object={changePasswordState}
					onChange={setChangePasswordState}
					props={[
						{ label: 'Old Password', key: 'oldPassword', type: 'password' },
						{ label: 'Password', key: 'password', type: 'password' },
						{ label: 'Confirm Password', key: 'confirmPassword', type: 'password' }
					]}
					tools={[
						{ label: 'Set Password', render: () => <input type="button" value="Set Password" onClick={changePassword} /> }
					]}
				/>
			</PageSection>

			<PageSection title="Two-Factor Authentication" level={3}>
				{
					auth.profile.auth.twoFactor?.enabled ? (
						<div>
							<p>Two-factor authentication is enabled</p>
							
							{
								twoFactorData.recoveryCodes?.length > 0
								? (
									<div>
										<p>Recovery codes:</p>
										<ul>
											{twoFactorData.recoveryCodes?.map(code => <li key={code}><code>{code}</code></li>)}
										</ul>
									</div>
								)
								: (
									<div>
										<p>
											<button onClick={() => {
												api.post('/api/auth/profile/twofactor/recoverycodes', { code: twoFactorData.recoveryTotpCode })
												.then(json => {
													setTwoFactorData({ ...twoFactorData, recoveryCodes: json.recoveryCodes });
												});
											}}>Regenerate Recovery Codes</button>
										</p>
									</div>
								)
							}

							<button onClick={() => {
								api.post('/api/auth/profile/twofactor/disable', { code: twoFactorData.disableCode })
								.then(() => {
									auth.update({ ...auth.profile, auth: { ...auth.profile.auth, twoFactor: { enabled: false } } });
								});
							}}>Disable 2FA</button>
						</div>
					) : (
						<div>
							<p>Two-factor authentication is not enabled</p>

							{
								twoFactorData.secret ? (
									<div>
										<p>Scan this QR code with your authenticator app:</p>
										<img src={twoFactorData.qrCode} alt="QR Code" />
										<p>Or enter this code manually: <code>{twoFactorData.secret}</code></p>
										<p>Enter the code from your authenticator app:</p>
										<input type="text" value={twoFactorData.verifyCode} onChange={e => setTwoFactorData({ ...twoFactorData, verifyCode: e.target.value })} />
										<button onClick={() => {
											api.post('/api/auth/profile/twofactor/enable', { code: twoFactorData.verifyCode })
											.then(json => {
												reloadProfile();

												setTwoFactorData({ ...twoFactorData, enabled: true, secret: null, recoveryCodes: json.recoveryCodes });
											});
										}}>Verify Code and Enable 2FA</button>
									</div>
								) : (
									<button onClick={() => {
										api.post('/api/auth/profile/twofactor/enable')
										.then(json => {
											setTwoFactorData(json);
										});
									}}>Configure 2FA</button>
								)
							}
						</div>
					)
				}
			</PageSection>

			<PageSection title="Branches" level={4}>
				<DataTable
					data={auth.profile.branches}
					columns={[
						{ key: 'name', label: 'Name', render: (row) => (<Link to={`/${row.id}`}>{row.name}</Link>) },
						{ key: 'id', label: 'ID' },
					]}
					actions={[
						{ label: 'View', icon: 'eye', link: (row) => `/system/branches/${row.id}` },
						{ label: 'Edit', icon: 'edit', link: (row) => `/system/branches/${row.id}/edit` },
						{ label: 'Delete', icon: 'trash', link: (row) => `/system/branches/${row.id}/delete` },
					]}
				/>
			</PageSection>

			{
				auth.profile.branches.find(o => o.id === 'system') ?
				(
					<PageSection title="System Admin Access" level={5}>You are a system administrator. <Link to="/system">Manage system</Link></PageSection>
				)
				: null
			}

			{/* Show user's devices, highlighting the current device where the ID matches the udevid cookie */}
			<PageSection title="Devices" level={4}>
				<DataTable
					data={auth.profile.devices}
					columns={[
						{ key: 'id', label: 'ID', render: (row) => row.id === cookieDeviceID ? <b>{row.id} (this device)</b> : row.id },
						{ key: 'os', label: 'OS', render: (row) => `${row.os?.name} ${row.os?.version}` },
						{ key: 'browser', label: 'Browser', render: (row) => `${row.browser?.name} ${row.browser?.version}` },
						{ key: 'device', label: 'Device', render: (row) => `${row.device?.type} ${row.device?.vendor} ${row.device?.model}` },
						{ key: 'lastSeen', label: 'Last Seen', render: (row) => new Date(row.lastSeen).toLocaleString() },
						{ key: 'lastAddress', label: 'Last Address' },
					]}
				/>
			</PageSection>

			<PageSection title="Raw	Data" level={4}>
				<RawJsonDisplay data={auth} />
			</PageSection>

		</div>
	);
}

export default Profile;